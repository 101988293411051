import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Container from 'typedi'
import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'ImportView',
  computed: {
    ...mapState('registration', ['pastImports'])
  }
})

export default class ImportView extends GtrSuper {
  data () {
    return {
      loading: false,
      imports: {
        loading: false,
        items: [],
        headers: [
          {
            text: 'File Name',
            align: 'start',
            sortable: false,
            value: 'file_url'
          }, {
            text: 'Added Records',
            align: 'start',
            sortable: false,
            value: 'totals.total_added'
          }, {
            text: 'Updated Records',
            align: 'start',
            sortable: false,
            value: 'totals.total_updated'
          }, {
            text: 'Failures',
            align: 'start',
            sortable: false,
            value: 'totals.total_failed'
          }, {
            text: 'Status',
            align: 'start',
            sortable: false,
            value: 'status'
          }, {
            text: 'Added',
            align: 'start',
            sortable: true,
            value: 'created_at'
          }
        ]
      }
    }
  }

  async mounted () {
    this.$data.loading = true
    this.fetchPastImports()
    this.$data.loading = false
  }

  private async fetchPastImports () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        dataType: 'sessions'
      }
      const response = await this.$store.dispatch('registration/getPastImports', payload)
      const responseItems: any[] = []
      for (const itemIndex in response.data) {
        const item = response.data[itemIndex]
        if (item.data_type === 'sessions') {
          responseItems.push(item)
        }
      }
      this.$data.imports.items = responseItems
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async onFileUploadChange (files: File[]) {
    try {
      this.$data.loading = true
      const payload = {
        fileData: files[0],
        company_uuid: this.$route.params.company_uuid,
        data: {
          event_uuid: this.$route.params.event_uuid,
          data_type: 'sessions',
          mode: 'IMPORT_ONLY',
          option_group_uuid: this.$route.params.option_uuid
        }
      }
      const response = await this.$store.dispatch('registration/importFile', payload)
      this.$router.push({ name: 'level-two.modules.track.sessions.import.edit', params: { event_uuid: this.$route.params.event_uuid, import_uuid: response.data.uuid } })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async downloadSample () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        type: 'sessions'
      }
      const response = await this.$store.dispatch('registration/getParticipantImportSample', payload)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  handleGoBackToRecords () {
    this.$router.push({
      name: 'level-two.modules.track.sessions',
      params: {
        event_uuid: this.$route.params.event_uuid
      }
    })
  }

  handleRowClick (item) {
    this.$router.push({
      name: 'level-two.modules.track.sessions.import.edit',
      params: {
        event_uuid: this.$route.params.event_uuid,
        import_uuid: item.uuid
      }
    })
  }
}
